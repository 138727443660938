<template>
	<div
		class="position-fixed"
		style="left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; z-index: 9999"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer pa-20 flex-column justify-center"
			style="width: 100%; height: 100%;"
		>
			<div
				style="min-width: 320px; max-width: 95%; margin: 0 auto;"
				class="bg-white flex-column radius-20 box text-left"
			>
				<div class="under-line flex-row justify-space-between items-center pa-10-20">
					<h3 class="">
						<slot name="title"></slot>
					</h3>

					<v-icon
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div class="pa-10-20">
					<ul class="box-select">
						<slot name="list"></slot>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PopupSelect'
}
</script>